// @flow

import React from 'react';
import { observer, inject } from 'mobx-react';
import { graphql } from 'gatsby';
import { Provider } from 'mobx-react';

import {JSON_UPDATES} from '../constants/json';
import {STORE_JSON} from '../constants/stores';
import JsonStore from '../stores/json-store';
import styles from './updates.module.scss';
import Metadata from "../components/Metadata";
import BlurUpImage from "../components/BlurUpImage";
import getImageUrl from "../utils/get-image-url";
import Markdown from "../components/Markdown";
import Button from "../components/Button";


type UpdatesProps = {
  json: JsonStore,
  data: any,
  location: any,
}

@inject(STORE_JSON)
@observer
class UpdatesPage extends React.Component<UpdatesProps, any> {

  stores: any;

  constructor(props: UpdatesProps, context: any) {
    super(props, context);
    this.props[STORE_JSON].setJsonFromGraphQL(this.props.data, JSON_UPDATES);
  }

  componentDidMount() {
    if (process.env.GATSBY_PREVIEW === 'true' && window) {
      this.props.json.getData(JSON_UPDATES, {
        contentType: 'updates',
        id: '6idBb13bFeSWQMm2y8WqOk'
      });
    }
  }

  render() {

    const data = this.props[STORE_JSON].store.get(JSON_UPDATES) || this.props.data;

    return (
      <Provider {...this.stores}>
        <>
          <Metadata data={this.props.data.updatesPage.metadata}
                    location={this.props.location}/>
          <div className={styles.container}>
            <div className={styles.slides}>
              {
                data.updatesPage.updates.map((slide, index) => {
                  return (
                    <div key={`${index}updatePage`}
                         className={styles.slide}>
                      <div className={styles.image}>
                        <BlurUpImage src={getImageUrl(slide.thumbnailPhoto.src)}
                                     srcSmall={getImageUrl(slide.thumbnailPhoto.srcSmall)}/>
                      </div>
                      <div className={styles.blurb}>
                        <h4>{slide.name}</h4>
                        <div className={styles.markdown}>
                          <Markdown markdown={slide.blurb}/>
                        </div>
                        <div className={styles.button}>
                          <Button display={slide.buttonText || 'Read More'}
                                  url={slide.url && `updates/${slide.url}` || slide.buttonUrlInternal || ''}
                                  external={slide.buttonUrlExternal || ''}
                                  type={'blue'}/>
                        </div>
                      </div>
                    </div>
                  )
                })
              }
            </div>
          </div>
        </>
      </Provider>
    )
  }
}

export const query = graphql`
query {
  updatesPage: contentfulUpdates(contentful_id: {eq: "6idBb13bFeSWQMm2y8WqOk"}) {
    metadata {
      title
      description
      image {
        file {
          url
        }
      }
      ogTitle
      ogDescription
      twitterCard
    }
    updates {
      metadata {
        title
        description
        image {
          file {
            url
          }
        }
        ogTitle
        ogDescription
        twitterCard
      }
      name
      url
      buttonUrlExternal
      buttonUrlInternal
      buttonText
      blurb {
        markdown: blurb
      }
      thumbnailPhoto {
        type
        srcSmall {
          file {
            url
          }
        }
        src {
          file {
            url
          }
        }
      }
    }
  }
}`;

export default UpdatesPage;
